import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Metcon Week!`}</em></strong></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`250M Row`}</p>
    <p>{`10/Arm Alternating DB Snatch (50/35)`}</p>
    <p>{`25ft DB OH Walking Lunge, Right Arm`}</p>
    <p>{`10/Arm Alternating DB Snatch`}</p>
    <p>{`25ft DB OH Walking Lunge, Left Arm`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The gym will close today immediately following the 10:00 class.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      